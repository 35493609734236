<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Редактирование типа перевозки «{{ data.name }}»
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <p>
                <b-form-checkbox
                  id="access-auto"
                  v-model="form.firstBid"
                  name="first-bid"
                >
                  Доступен для розыгрыша по первой ставке
                </b-form-checkbox>
              </p>
            </b-col>
            <b-col md="12">
              <p class="mb-1">
                Время до отмены аукциона<span class="text-danger">*</span>
              </p>
              <b-form-group
                id="passportIssuedByInputGroup5"
                style="max-width: 290px"
              >
                <b-form-input
                  id="time-to-cancel"
                  v-model="form.timeToCancel"
                  type="number"
                  min="1"
                  max="9999"
                  placeholder="Введите время в минутах"
                  name="time-to-cancel"
                />
                <small class="text-muted">Значение должно быть в диапазоне от 1 до 9999</small>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="timeToCancelValidate"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import notifications from '../../components/mixins/notifications';
import {
  routesTransportationTypeRead,
  routesTransportationTypeSettingsUpdate,
} from '../../services/api';

export default {
  name: 'TransportationNamesEdit',
  mixins: [validationMixin, notifications],
  props: ['transportationNameId'],
  data() {
    return {
      data: {
        name: null,
      },
      form: {
        firstBid: false,
        timeToCancel: null,
      },
      loading: false,
    };
  },
  computed: {
    timeToCancelValidate() {
      return !(this.form.timeToCancel > 0 && this.form.timeToCancel < 10000);
    },
    formStr() {
      const calculationFormData = {};
      calculationFormData.id = this.transportationNameId;
      calculationFormData.firstBid = this.form.firstBid;
      calculationFormData.timeToCancel = this.form.timeToCancel;
      return calculationFormData;
    },
  },
  mounted() {
    this.routesTransportationTypeRead(this.transportationNameId);
  },
  methods: {
    async routesTransportationTypeRead(transportationNameId) {
      this.loading = true;
      const response = await routesTransportationTypeRead(transportationNameId);
      if (response && response.status === 200) {
        if (response.data.name) {
          this.data.name = response.data.name;
        }
        if (response.data.transportationSettings) {
          this.form.firstBid = response.data.transportationSettings.firstBid;
          if (response.data.transportationSettings.timeToCancel) {
            this.form.timeToCancel = response.data.transportationSettings.timeToCancel;
          }
        }
      }
      this.loading = false;
    },
    async onSubmit() {
      this.loading = true;
      const response = await routesTransportationTypeSettingsUpdate(this.transportationNameId, this.formStr);
      if (response && response.status === 200) {
        this.showSuccess('Тип перевозки обновлен');
        this.$router.push({path: '/transportation-names'});
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>

